<template>
    <q-tab-panel name="EventStartListTab">
        <q-tabs v-model="selClass" align="left" dense no-caps narrow-indicator active-color="secondary">
            <q-tab :ripple="false" name="-all" :label="ts( 'allClasses' )"/>
            <q-tab :ripple="false" :name="boatClass.boatClass.id" :label="boatClass.boatClass.name"
                   v-for="boatClass in attendedClasses"
                   :key="boatClass.id"/>
        </q-tabs>

        <qe-form>
            <q-table flat :rows="attendedBoats" :expanded="boatsExpanded" :columns="boatsColumns"
                     :visible-columns="boatsVisibleColumns" row-key="id"
                     binary-state-sort
                     :rows-per-page-options="[0,5,10,15,20,25,30]" class="q-pt-md">
                <template v-slot:body="props">
                    <q-tr :props="props" :class="{ 'invalid': !props.row.isValid, 'current-boat': props.expand }">
                        <q-td v-for="col in props.cols" :key="col.name" :props="props">
                            <div v-if="col.name === 'actions'">
                                <q-btn
                                    flat :icon="props.expand ? 'person_remove' : 'person_add'" color="secondary"
                                    class="qe-ignore-focus"
                                    :title="props.expand ? ts( 'closeCrewList' ) : ts( 'openCrewList' )"
                                    @click="toggleExpanded( props.row.id )"/>
                                <q-btn
                                    flat icon="delete" color="negative" class="qe-ignore-focus"
                                    :title="ts( 'removeBoat' )"
                                    @click="removeBoat( props.row )"/>
                            </div>
                            <div v-else-if="col.name === 'crew'" class="minicrewlist">
                                <div v-for="crew in props.row.orderedCrewList" :key="crew.id"
                                     :class="crew.captain ? 'captain' : ''">
                                    {{ crew.reverseFullName }}
                                </div>
                            </div>
                            <div v-else-if="col.name === 'sailNo'" class="cursor-pointer">
                                {{ props.row.sailNo }}
                                <q-popup-edit v-model="props.row.sailNumber" :title="ts( 'editSailNo' )" auto-save
                                              v-slot="scope" buttons
                                              @save="( val, origVal ) => updateSailNumber( props.row, val, origVal )">
                                    <q-input type="number" v-model.number="scope.value" dense autofocus
                                             @keyup.enter="scope.set"/>
                                </q-popup-edit>
                            </div>
                            <div v-else-if="col.name === 'boatName'" class="cursor-pointer">
                                {{ props.row.name }}
                                <q-icon name="more_horiz" color="secondary"
                                        v-if="props.row.boatClass.useBoatNames && ( props.row.name === undefined || props.row.name === null || props.row.name.length === 0 )"/>
                                <q-popup-edit v-model="props.row.name" :title="ts( 'editBoatName' )" auto-save
                                              v-slot="scope" buttons
                                              @save="( val, origVal ) => updateSailName( props.row, val, origVal )">
                                    <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set"/>
                                </q-popup-edit>
                            </div>
                            <div v-else-if="col.name === 'detail'" class="cursor-pointer">
                                {{ props.row.detail }}
                                <q-icon name="more_horiz" color="secondary"
                                        v-if="props.row.detail === undefined || props.row.detail === null || props.row.detail.length === 0"/>
                                <q-popup-edit v-model="props.row.detail" :title="ts( 'editNote' )" auto-save
                                              v-slot="scope" buttons
                                              @save="( val, origVal ) => updateDetail( props.row, val, origVal )">
                                    <q-input v-model="scope.value" dense autofocus @keyup.enter="scope.set"/>
                                </q-popup-edit>
                            </div>
                            <span v-else>{{ col.value }}</span>
                        </q-td>
                    </q-tr>
                    <q-tr v-show="props.expand" :props="props" class="current-boat">
                        <q-td colspan="100%">
                            <q-table
                                flat :rows="props.row.orderedCrewList" :columns="crewColumns" row-key="id"
                                class="q-pa-md q-ml-xl q-mr-xl"
                                v-draggable-table="{ options: { mode: props.row.orderedCrewList.length > 2 ? 'row' : 'none', onlyBody: true, dragHandler: 'tr.draggable', dragula: { accepts: reorderOnAccept } }, onDrop: reorderCrew }"
                                v-model:pagination="pagination" :rows-per-page-options="[0]">
                                <template v-slot:body="props">
                                    <q-tr :props="props"
                                          :class="( props.row.isValid ? '' : 'invalid' ) + ( props.row.captain ? '' : ' draggable' )">
                                        <q-td v-for="col in props.cols" :key="col.name" :props="props">
                                            <q-icon
                                                v-if="col.name === 'sex'"
                                                :name="props.row.sex === 'male' ? 'man' : 'woman'"
                                                :color="props.row.sex === 'male' ? 'blue' : 'red'"
                                                size="1.8em"/>
                                            <q-icon
                                                v-else-if="col.name === 'captain'" name="star" color="orange"
                                                size="1.8em" v-if="props.row.captain"/>
                                            <span v-else-if="col.name === 'actions'">
                                                        <q-btn v-if="!props.row.captain && !props.row.isPublic"
                                                               flat icon="star" color="secondary"
                                                               class="qe-ignore-focus" :title="ts( 'promoteCaptain' )"
                                                               @click="promoteCaptain( props.row )"/>
                                                        <q-btn
                                                            flat icon="phone" color="secondary" class="qe-ignore-focus"
                                                            :title="ts( 'crewContact' )"
                                                            @click="crewContact( props.row )"/>
                                                        <q-btn
                                                            flat icon="delete" color="negative" class="qe-ignore-focus"
                                                            :title="ts( 'removeCrew' )"
                                                            @click="removeCrew( props.row )"/>
                                                    </span>
                                            <span v-else>{{ col.value }}</span>
                                        </q-td>
                                    </q-tr>
                                </template>
                                <template #no-data>
                                    <div>{{ ts( 'noCrew' ) }}</div>
                                </template>
                                <template v-slot:bottom-row>
                                    <q-td colspan="4">
                                        <qe-combo-box
                                            v-model="newCrewMember" :label="ts( 'newRegisteredMember' )"
                                            :options="crewList"
                                            @filter="crewFilter" @keydown.enter="chooseOnlyOneMember"
                                            @keydown.tab="chooseOnlyOneMember">
                                            <template v-slot:option="scope">
                                                <q-item v-bind="scope.itemProps">
                                                    <q-item-label>{{ scope.opt.label }}</q-item-label>
                                                </q-item>
                                            </template>
                                        </qe-combo-box>
                                    </q-td>
                                    <q-td class="text-center" colspan="3">
                                        <qe-btn
                                            :label="ts( 'add' )" color="secondary"
                                            @click="addNewCrew( props.row )"/>
                                        <qe-btn
                                            class="q-ml-xl"
                                            :label="ts( 'addForeignCrew' )" color="secondary"
                                            @click="addNewForeignCrew( props.row )"
                                        />
                                        <qe-btn
                                            class="q-ml-sm"
                                            :label="ts( 'addOneTimeCrew' )" color="secondary"
                                            @click="addNewOneTimeCrew( props.row )"
                                        />
                                        <qe-btn
                                            class="q-ml-sm"
                                            v-if="props.row.eventClass.weight === 1 && props.row.crew.length >= 1"
                                            :label="ts( 'addPublicCrew' )" color="secondary"
                                            @click="addNewPublicCrew( props.row )"
                                        />
                                    </q-td>
                                </template>
                            </q-table>
                        </q-td>
                    </q-tr>
                </template>
                <template #no-data>
                    <div>{{ ts( 'noBoats' ) }}</div>
                </template>
                <template v-slot:bottom-row>
                    <q-td v-if="selClass === '-all'">
                        <qe-combo-box
                            v-model="newBoatClass" ref="newBoatClassField" :label="ts( 'boatClass' )"
                            :options="boatClassList" autofocus
                            class="boat-class-column"
                            @filter="boatClassFilter" @keydown.enter="chooseOnlyOneBoatClass"
                            @keydown.tab="chooseOnlyOneBoatClass">
                            <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps">
                                    <q-item-label>{{ scope.opt.label }}</q-item-label>
                                </q-item>
                            </template>
                        </qe-combo-box>
                    </q-td>
                    <q-td class="row inline no-wrap">
                        <qe-combo-box
                            v-model="newBoatCountry" ref="newBoatCountryField" :label="ts( 'country' )"
                            :options="countryList" option-label="value"
                            class="q-mr-md boat-country-column"
                            @filter="countryFilter" @keydown.enter="chooseOnlyOneCountry"
                            @keydown.tab="chooseOnlyOneCountry">
                            <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps">
                                    <q-item-label>{{ scope.opt.label }}</q-item-label>
                                </q-item>
                            </template>
                        </qe-combo-box>
                        <qe-input type="number" v-model.number="newBoatSailNo" :label="ts( 'sailNo' )"
                                  class="boat-sailno-column"></qe-input>
                    </q-td>
                    <q-td v-if="currentBoatClassUseNames">
                        <qe-input v-model="newBoatName"></qe-input>
                    </q-td>
                    <q-td>
                        <qe-input v-model="newBoatDetail"></qe-input>
                    </q-td>
                    <q-td class="text-center">
                        <qe-btn
                            :label="ts( 'addBoat' )" color="secondary"
                            @click="addNewBoat()"/>
                    </q-td>
                </template>
            </q-table>
        </qe-form>

        <div class="row justify-between q-mt-lg">
            <q-btn :label="ts( 'updateRegisteredFromServer' )" outline color="secondary" @click="updateFromServer"/>
            <q-btn
                :label="ts( 'printStartList' )" outline color="secondary"
                v-if="selClass !== '-all' && attendedBoats.length > 0"
                @click="printStartList"/>
        </div>
    </q-tab-panel>
</template>

<script setup lang="ts">
import { authenticate, checkAuth } from '@/network/auth'
import apiRequest from '@/network/httpClient'
import Confirmation from '@dialogs/Confirmation.vue'
import CrewContact from '@dialogs/CrewContact.vue'
import ForeignCrewRegistration from '@dialogs/ForeignCrewRegistration.vue'
import InfoBox from '@dialogs/InfoBox.vue'
import LogIn from '@dialogs/LogIn.vue'
import OneTimeCrewRegistration from '@dialogs/OneTimeCrewRegistration.vue'
import PublicCrewRegistration from '@dialogs/PublicCrewRegistration.vue'
import UpdateRegistrations from '@dialogs/UpdateRegistrations.vue'
import Country from '@model/Country'
import EventResult from '@model/EventResult'
import { Defaults, Options } from '@model/Setting'
import QeBtn from '@qe/qeBtn.vue'
import QeComboBox from '@qe/qeComboBox.vue'
import QeForm from '@qe/qeForm.vue'
import QeInput from '@qe/qeInput.vue'
import EventBoat from '@model/EventBoat'
import EventCrew, { crewDump, crewHash } from '@model/EventCrew'
import BoatClassesRepo from '@repo/BoatClassesRepo'
import CountriesRepo from '@repo/CountriesRepo'
import EventsRepo from '@repo/EventsRepo'
import MembersRepo from '@repo/MembersRepo'
import { DateFormat, ds, ts } from '@/plugins/i18n-formatted'
import nvl from '@/utils/nvl'
import SettingsRepo from '@repo/SettingsRepo'
import { useRepo } from 'pinia-orm'
import { QSelect, useQuasar } from 'quasar'
import { rodnecislo } from 'rodnecislo'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { sprintf } from 'sprintf-js'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.addVirtualFileSystem( pdfFonts )

const quasar = useQuasar()
const route = useRoute()

const event = computed( () => ( useRepo( EventsRepo ).getEvent( String( route.params.eventId ), true ) ) )

useRepo( EventsRepo ).selectEvent( event.value.id )

const selClass = ref( '-all' )

const boatsExpanded = ref( [] )

const countriesRepo = computed( () => ( useRepo( CountriesRepo ) ) )
const membersRepo = computed( () => ( useRepo( MembersRepo ) ) )

const attendedClasses = computed( () => ( event.value.orderedClasses ) )
const attendedBoats = computed( () => ( event.value.orderedBoats( selClass.value === '-all' ? null : selClass.value ) ) )

const boatClassList = ref( event.value.orderedClassesList )
const countryList = ref( countriesRepo.value.countryForSailList() )
const crewList = ref( membersRepo.value.orderedList() )

const pagination = ref()

const newBoatClass = ref()
const newBoatCountry = ref( 'CZE' )
const newBoatSailNo = ref()
const newBoatName = ref()
const newBoatDetail = ref()
const newCrewMember = ref()

const newBoatCountryField = ref()
const newBoatClassField = ref()

const boatsColumns = ref( [
                              {
                                  name: 'boatClass',
                                  label: ts( 'boatClass' ),
                                  align: 'left',
                                  field: row => row.boatClass.name,
                                  sortable: true,
                              },
                              {
                                  name: 'sailNo',
                                  required: true,
                                  label: ts( 'sailNo' ),
                                  align: 'left',
                                  field: row => row.sailNo,
                                  sortable: true,
                              },
                              {
                                  name: 'boatName',
                                  label: ts( 'boatName' ),
                                  align: 'left',
                                  field: row => nvl( row.name, '' ),
                                  sortable: true,
                              },
                              {
                                  name: 'detail',
                                  label: ts( 'note' ),
                                  align: 'left',
                                  field: row => nvl( row.detail, '' ),
                                  sortable: true,
                              },
                              {
                                  name: 'crew',
                                  required: true,
                                  label: ts( 'crew' ),
                                  align: 'left',
                                  field: () => '',
                                  sortable: true,
                              },
                              {
                                  name: 'actions',
                                  label: ts( 'actions' ),
                                  field: () => '',
                                  align: 'center',
                              },
                          ] )

const crewColumns = ref( [
                             {
                                 name: 'regId',
                                 label: ts( 'regId' ),
                                 align: 'left',
                                 field: row => row.regId,
                             },
                             {
                                 name: 'captain',
                                 label: ts( 'captain' ),
                                 align: 'center',
                                 field: row => row.captain,
                             },
                             {
                                 name: 'sex',
                                 label: ts( 'sex' ),
                                 align: 'center',
                                 field: row => row.sex,
                             },
                             {
                                 name: 'fullName',
                                 label: ts( 'fullName' ),
                                 align: 'left',
                                 field: row => row.reverseFullName,
                             },
                             {
                                 name: 'birthYear',
                                 label: ts( 'birthYear' ),
                                 align: 'left',
                                 field: row => row.birthYear,
                             },
                             {
                                 name: 'club',
                                 label: ts( 'club' ),
                                 align: 'left',
                                 field: row => row.visibleClub,
                             },
                             {
                                 name: 'actions',
                                 label: ts( 'actions' ),
                                 align: 'center',
                                 field: () => '',
                             },
                         ] )

const currentBoatClassUseNames = computed( () => ( ( selClass.value === '-all' && attendedClasses.value.filter( eventClass => eventClass.boatClass.useBoatNames ).length > 0 )
                                                   || ( attendedClasses.value.filter( eventClass => eventClass.boatClass.id === selClass.value )[ 0 ] )?.boatClass.useBoatNames ) )

const boatsVisibleColumns = computed( () => ( boatsColumns.value.map( col => col.name )
                                                          .filter( name => ( currentBoatClassUseNames.value || name !== 'boatName' ) )
                                                          .filter( name => ( selClass.value === '-all' ? true : name !== 'boatClass' ) )
) )

function reorderOnAccept ( el, target, source, sibling ) {
    return sibling?.querySelector( '.draggable' )
}

function countryFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            countryList.value = countriesRepo.value.countryForSailList()
        } )
        return
    }

    update(
        () => {
            const needle = val.toSearchable()
            countryList.value = countriesRepo.value.countryForSailList().filter( country => country.label.toSearchable().indexOf( needle ) > -1 )
        },
        ( ref: QSelect ) => {
            if ( val !== '' && ref.options.length > 0 ) {
                ref.setOptionIndex( -1 )
                ref.moveOptionSelection( 1, true )
            }
        },
    )
}

function chooseOnlyOneCountry () {
    if ( countryList.value != null && countryList.value.length == 1 ) {
        newBoatCountry.value = countryList.value[ 0 ].value
        newBoatCountryField.value.focus()
    }
}

function boatClassFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            boatClassList.value = event.value.orderedClassesList
        } )
        return
    }

    update(
        () => {
            const needle = val.toSearchable()
            boatClassList.value = event.value.orderedClassesList.filter( boatClass => boatClass.label.toSearchable().indexOf( needle ) > -1 )
        },
        ( ref: QSelect ) => {
            if ( val !== '' && ref.options.length > 0 ) {
                ref.setOptionIndex( -1 )
                ref.moveOptionSelection( 1, true )
            }
        },
    )
}

function chooseOnlyOneBoatClass () {
    if ( boatClassList.value != null && boatClassList.value.length == 1 ) {
        newBoatClass.value = boatClassList.value[ 0 ].value
        newBoatClassField.value.focus()
    }
}

function performAddBoat ( boatClass ) {
    useRepo( EventBoat ).save( {
                                   eventId: event.value.id,
                                   boatClass: boatClass,
                                   country: newBoatCountry.value,
                                   sailNumber: String( newBoatSailNo.value ),
                                   name: newBoatName.value,
                                   detail: newBoatDetail.value,
                               } )

    newBoatClass.value = null
    newBoatCountry.value = 'CZE'
    newBoatSailNo.value = null
    newBoatName.value = null
    newBoatDetail.value = null
}

function addNewBoat () {
    if ( newBoatCountry.value === undefined || newBoatSailNo.value === undefined || newBoatCountry.value === null || newBoatSailNo.value === null
         || newBoatSailNo.value.length === 0 || newBoatCountry.value.length === 0 || ( selClass.value === '-all' && newBoatClass.value == null ) ) {
        quasar.dialog( {
                           component: InfoBox,
                           componentProps: {
                               message: ts( 'missingBoatData' ),
                           },
                       } )

        return
    }

    let boatClass = ( selClass.value === '-all' ? newBoatClass.value : selClass.value )

    boatClass = useRepo( BoatClassesRepo ).find( boatClass )

    if ( boatClass === null )
        return

    if ( useRepo( EventBoat ).where( 'eventId', event.value.id ).where( 'boatClassId', newBoatClass.value ).where( 'country', newBoatCountry.value ).where( 'sailNumber', String( newBoatSailNo.value ) ).get().length > 0 ) {
        quasar.dialog( {
                           component: InfoBox,
                           componentProps: {
                               icon: 'warning',
                               color: 'accent',
                               message: ts( 'duplicateSailNo', {
                                   sailNumber: newBoatCountry.value + ' ' + newBoatSailNo.value,
                                   boatClass: boatClass.name,
                               } ),
                           },
                       } )

        return
    }

    if ( useRepo( EventBoat ).where( 'eventId', event.value.id ).where( 'sailNumber', String( newBoatSailNo.value ) ).get().length === 0 ) {
        performAddBoat( boatClass )
        return
    }

    quasar.dialog( {
                       component: Confirmation,
                       componentProps: {
                           icon: 'warning',
                           question: ts( 'duplicateSailNoWarn', {
                               sailNumber: newBoatSailNo.value,
                           } ),
                       },
                   } )
          .onOk( () => {
              performAddBoat( boatClass )
          } )
}

function removeBoat ( eventBoat ) {
    quasar.dialog( {
                       component: Confirmation,
                       componentProps: {
                           icon: 'delete',
                           question: ts( 'removeBoatFromEvent', {
                               eventTitle: eventBoat.event?.fullTitle,
                               boatClass: eventBoat.boatClass?.name,
                               sailNo: eventBoat.sailNo,
                           } ),
                       },
                   } )
          .onOk( () => {
              useRepo( EventCrew ).where( 'eventBoatId', eventBoat.id ).delete()
              useRepo( EventResult ).where( 'eventBoatId', eventBoat.id ).delete()
              useRepo( EventBoat ).destroy( eventBoat.id )
          } )
}

function performAddCrew ( eventBoat ) {
    useRepo( EventCrew ).save( {
                                   eventBoatId: eventBoat.id,
                                   memberId: newCrewMember.value,
                                   captain: eventBoat.crew.length === 0,
                                   order: eventBoat.crew.length === 0 ? 1 : Math.max( ...eventBoat.crew.map( crew => crew.order ) ) + 1,
                               } )

    newCrewMember.value = ''
}

function addNewCrew ( eventBoat ) {
    if ( newCrewMember.value === undefined || newCrewMember.value === '' || newCrewMember.value.length === 0 ) {
        quasar.dialog( {
                           component: InfoBox,
                           componentProps: {
                               message: ts( 'missingCrewData' ),
                           },
                       } )

        return
    }

    if ( useRepo( EventCrew ).where( 'eventBoatId', eventBoat.id ).where( 'memberId', newCrewMember.value ).get().length > 0 ) {
        quasar.dialog( {
                           component: InfoBox,
                           componentProps: {
                               icon: 'warning',
                               color: 'accent',
                               message: ts( 'duplicateCrewOnBoat', { crew: useRepo( MembersRepo ).find( newCrewMember.value )?.fullName } ),
                           },
                       } )

        return
    }

    if ( useRepo( EventCrew ).where( 'memberId', newCrewMember.value ).with( 'eventBoat', ( query ) => ( query.where( 'eventId', event.value.id ) ) )
                             .get().filter( ( crew ) => ( crew.eventBoat !== null ) ).length == 0 ) {
        performAddCrew( eventBoat )
        return
    }

    quasar.dialog( {
                       component: Confirmation,
                       componentProps: {
                           icon: 'warning',
                           question: ts( 'duplicateCrewOnEvent', { crew: useRepo( MembersRepo ).find( newCrewMember.value )?.fullName } ),
                       },
                   } )
          .onOk( () => {
              performAddCrew( eventBoat )
          } )
}

function crewFilter ( val, update ) {
    if ( val === '' ) {
        update( () => {
            crewList.value = membersRepo.value.orderedList()
        } )
        return
    }

    update(
        () => {
            const needle = val.toSearchable()
            crewList.value = membersRepo.value.orderedList().filter( member => member.searchLabel.toSearchable().indexOf( needle ) > -1 )
        },
        ( ref: QSelect ) => {
            if ( val !== '' && ref.options.length > 0 ) {
                ref.setOptionIndex( -1 )
                ref.moveOptionSelection( 1, true )
            }
        },
    )
}

function chooseOnlyOneMember () {
    if ( crewList.value != null && crewList.value.length == 1 )
        newCrewMember.value = crewList.value[ 0 ].value
}

function toggleExpanded ( val ) {
    boatsExpanded.value = boatsExpanded.value[ 0 ] === val ? [] : [ val ]
    newCrewMember.value = undefined
}

function removeCrew ( boatCrew ) {
    quasar.dialog( {
                       component: Confirmation,
                       componentProps: {
                           icon: 'delete',
                           question: ts( 'removeCrewFromBoat', {
                               fullName: boatCrew.fullName,
                               sailNo: boatCrew.eventBoat.sailNo,
                           } ),
                       },
                   } )
          .onOk( () => {
              useRepo( EventCrew ).destroy( boatCrew.id )
          } )
}

function promoteCaptain ( boatCrew ) {
    const currentCaptain = useRepo( EventCrew ).where( 'eventBoatId', boatCrew.eventBoatId ).where( 'captain', true ).first()

    if ( currentCaptain === null )
        useRepo( EventCrew ).save( { id: boatCrew.id, captain: true } )
    else
        useRepo( EventCrew ).save( [ { id: currentCaptain.id, captain: false }, { id: boatCrew.id, captain: true } ] )
}

function crewContact ( boatCrew ) {
    quasar.dialog( {
                       component: CrewContact,
                       componentProps: {
                           crew: boatCrew,
                       },
                   } )
}

function updateSailNumber ( eventBoat, sailNumber, originalSailNumber ) {
    if ( sailNumber === originalSailNumber )
        return

    const newSailNumber = String( sailNumber )

    if ( newSailNumber === undefined || newSailNumber === null || newSailNumber.length === 0 ) {
        quasar.dialog( {
                           component: InfoBox,
                           componentProps: {
                               message: ts( 'missingSailNo' ) + ' ' + ts( 'keepSame' ),
                           },
                       } )

        return
    }

    if ( useRepo( EventBoat ).where( 'eventId', event.value.id ).where( 'boatClassId', eventBoat.boatClassId ).where( 'country', eventBoat.country ).where( 'sailNumber', newSailNumber ).get().length > 0 ) {
        quasar.dialog( {
                           component: InfoBox,
                           componentProps: {
                               icon: 'warning',
                               color: 'accent',
                               message: ts( 'duplicateSailNo', {
                                   sailNumber: eventBoat.country + ' ' + newSailNumber,
                                   boatClass: eventBoat.boatClass.name,
                               } ) + ' ' + ts( 'keepSame' ),
                           },
                       } )

        return
    }

    if ( useRepo( EventBoat ).where( 'eventId', event.value.id ).where( 'sailNumber', newSailNumber ).get().length === 0 ) {
        useRepo( EventBoat ).save( { id: eventBoat.id, sailNumber: String( sailNumber ) } )
        return
    }

    quasar.dialog( {
                       component: Confirmation,
                       componentProps: {
                           icon: 'warning',
                           question: ts( 'duplicateSailNoWarnEdit', {
                               sailNumber: newSailNumber,
                           } ),
                       },
                   } )
          .onOk( () => {
              useRepo( EventBoat ).save( { id: eventBoat.id, sailNumber: String( sailNumber ) } )
          } )
}

function updateSailName ( eventBoat, name, originalName ) {
    if ( name === originalName )
        return

    useRepo( EventBoat ).save( { id: eventBoat.id, name: name } )
}

function updateDetail ( eventBoat, detail, originalDetail ) {
    if ( detail === originalDetail )
        return

    useRepo( EventBoat ).save( { id: eventBoat.id, detail: detail } )
}

function reorderCrew ( from, to ) {
    const offset = ( pagination.value.page - 1 ) * pagination.value.rowsPerPage

    if ( boatsExpanded.value.length === 0 )
        return

    const selectedBoat = boatsExpanded.value[ 0 ]
    const eventBoat = useRepo( EventBoat ).with( 'crew' ).find( selectedBoat )

    const firstCrew = eventBoat.orderedCrewList[ offset + from - 1 ]
    const secondCrew = eventBoat.orderedCrewList[ offset + to - 1 ]

    const firstOrder = firstCrew.order
    const secondOrder = secondCrew.order

    const direction = firstOrder < secondOrder ? 1 : -1

    const allAffected = useRepo( EventCrew ).where( 'eventBoatId', firstCrew.eventBoatId ).where( 'captain', false )
                                            .where( 'order', ( value ) => {
                                                return direction === 1 ? ( value >= firstOrder && value <= secondOrder ) : ( value <= firstOrder && value >= secondOrder )
                                            } )
                                            .orderBy( 'order', direction === 1 ? 'asc' : 'desc' ).get()

    if ( allAffected.length <= 1 )
        return

    const firstOne = allAffected.shift()

    let prevOrder = firstOne.order
    let results = []

    allAffected.forEach( function ( item ) {
        results.push( { id: item.id, order: prevOrder } )

        prevOrder = item.order
    } )

    results.push( { id: firstOne.id, order: prevOrder } )

    useRepo( EventCrew ).save( results )
}

function addNewPublicCrew ( eventBoat ) {
    quasar.dialog( {
                       component: PublicCrewRegistration,
                       componentProps: {
                           title: ts( 'publicCrewReg' ),
                           save: ts( 'register' ),
                       },
                   } )
          .onOk( ( payload ) => {
              const publicCrew = useRepo( EventCrew ).where( 'isPublic', true ).whereHas( 'eventBoat', ( query ) => {
                  query.where( 'eventId', event.value.id )
              } ).orderBy( 'nonMemberRegId', 'desc' ).first()

              const licNo = publicCrew === null ? 1 : parseInt( nvl( publicCrew.nonMemberRegId?.substring( 5 ), '0' ) ) + 1

              useRepo( EventCrew ).save( {
                                             eventBoatId: eventBoat.id,
                                             nonMemberRegId: sprintf( '8888-%04d', licNo ),
                                             nonMemberFirstName: payload.firstName,
                                             nonMemberLastName: payload.lastName,
                                             nonMemberSex: payload.sex,
                                             nonMemberBirthDate: payload.birthDate,
                                             nonMemberEmail: payload.email,
                                             nonMemberPhone: payload.phone,
                                             captain: eventBoat.crew.length === 0,
                                             order: eventBoat.crew.length === 0 ? 1 : Math.max( ...eventBoat.crew.map( crew => crew.order ) ) + 1,
                                         } )
          } )
}

function addNewOneTimeCrew ( eventBoat ) {
    quasar.dialog( {
                       component: OneTimeCrewRegistration,
                       componentProps: {
                           title: ts( 'oneTimeCrewReg' ),
                           save: ts( 'register' ),
                       },
                   } )
          .onOk( ( payload ) => {
              const publicCrew = useRepo( EventCrew ).where( 'isOneTime', true ).whereHas( 'eventBoat', ( query ) => {
                  query.where( 'eventId', event.value.id )
              } ).orderBy( 'nonMemberRegId', 'desc' ).first()

              const licNo = publicCrew === null ? 1 : parseInt( nvl( publicCrew.nonMemberRegId?.substring( 5 ), '0' ) ) + 1

              const birthCode = payload.citizenship === Country.CZ ? rodnecislo( payload.birthCode ) : null

              useRepo( EventCrew ).save( {
                                             eventBoatId: eventBoat.id,
                                             nonMemberRegId: sprintf( '9999-%04d', licNo ),
                                             nonMemberFirstName: payload.firstName,
                                             nonMemberLastName: payload.lastName,
                                             nonMemberSex: ( payload.citizenship === Country.CZ && birthCode !== null ) ? ( birthCode.isFemale() ? 'female' : 'male' ) : payload.sex,
                                             nonMemberBirthDate: ( payload.citizenship === Country.CZ && birthCode !== null ) ? birthCode.birthDate() : payload.birthDate,
                                             nonMemberBirthCode: payload.citizenship === Country.CZ ? String( payload.birthCode ) : null,
                                             nonMemberCitizenship: payload.citizenship,
                                             nonMemberAddress: payload.citizenship === Country.CZ ? null : payload.address,
                                             nonMemberEmail: payload.email,
                                             nonMemberPhone: payload.phone,
                                             captain: eventBoat.crew.length === 0,
                                             order: eventBoat.crew.length === 0 ? 1 : Math.max( ...eventBoat.crew.map( crew => crew.order ) ) + 1,
                                         } )
          } )
}

function addNewForeignCrew ( eventBoat ) {
    quasar.dialog( {
                       component: ForeignCrewRegistration,
                       componentProps: {
                           title: ts( 'foreignCrewReg' ),
                           save: ts( 'register' ),
                       },
                   } )
          .onOk( ( payload ) => {
              const publicCrew = useRepo( EventCrew ).where( 'isForeign', true ).whereHas( 'eventBoat', ( query ) => {
                  query.where( 'eventId', event.value.id )
              } ).where( 'nonMemberRegId', ( value ) => {
                  return parseInt( value.substring( 0, 4 ) ) === payload.country
              } ).orderBy( 'nonMemberRegId', 'desc' ).first()

              const licNo = publicCrew === null ? 1 : parseInt( nvl( publicCrew.nonMemberRegId?.substring( 5 ), '0' ) ) + 1

              useRepo( EventCrew ).save( {
                                             eventBoatId: eventBoat.id,
                                             nonMemberRegId: sprintf( '%04d-%04d', payload.country, licNo ),
                                             nonMemberFirstName: payload.firstName,
                                             nonMemberLastName: payload.lastName,
                                             nonMemberSex: payload.sex,
                                             nonMemberBirthDate: payload.birthDate,
                                             nonMemberEmail: payload.email,
                                             nonMemberPhone: payload.phone,
                                             captain: eventBoat.crew.length === 0,
                                             order: eventBoat.crew.length === 0 ? 1 : Math.max( ...eventBoat.crew.map( crew => crew.order ) ) + 1,
                                         } )
          } )
}

function printStartList () {
    let counter = 0

    const eventClass = event.value.classAttended( selClass.value )

    const content = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [ 20, 40, 20, 20 ],
        header: ( currentPage ) => ( [
            {
                marginLeft: 20,
                marginRight: 20,
                marginTop: 15,
                table: {
                    headerRows: 0,
                    widths: [ '*', '*' ],
                    body: [
                        [
                            { text: ts( 'crewList' ), style: 'h1' },
                            { marginTop: 6, text: ts( 'pageNo', { pageNo: currentPage } ), alignment: 'right' },
                        ],
                    ],
                },
                layout: {
                    vLineWidth: () => ( 0 ),
                    hLineWidth: ( i, node ) => ( i === node.table.body.length ),
                    hLineColor: 'black',
                },
            },
        ] ),
        content: [
            {
                marginTop: 8,
                table: {
                    headerRows: 0,
                    widths: [ '*', 'auto' ],
                    body: [
                        [
                            {
                                text: [
                                    { text: ts( 'event' ) + ':  ', style: 'em' },
                                    { text: event.value.title?.shorten( 50 ), style: 'common' },
                                ],
                            },
                            {
                                text: [
                                    { text: ts( 'eventStart' ) + '  ', style: 'em' },
                                    { text: ds( new Date( event.value.from ), DateFormat.EventTo ), style: 'common' },
                                    { text: '  ' + ts( 'eventUntil' ) + '  ', style: 'em' },
                                    { text: ds( new Date( event.value.to ), DateFormat.EventTo ), style: 'common' },
                                ],
                            },
                        ],
                        [
                            {
                                text: [
                                    { text: ts( 'ctlBare' ) + ':  ', style: 'em' },
                                    { text: event.value.ctlId, style: 'common' },
                                ],
                            },
                            {
                                text: [
                                    { text: ts( 'mainReferee' ) + ':  ', style: 'em' },
                                    { text: event.value.mainReferee?.reverseFullNameWithId, style: 'common' },
                                ],
                            },
                        ],
                        [
                            {
                                text: [
                                    { text: ts( 'eventOrganizer' ) + ':  ', style: 'em' },
                                    { text: event.value.clubTitleShort?.shorten( 50 ), style: 'common' },
                                ],
                            },
                            {
                                text: [
                                    { text: ts( 'venue' ) + ':  ', style: 'em' },
                                    { text: event.value.venueTitle?.shorten( 50 ), style: 'common' },
                                ],
                            },
                        ],
                        [
                            {
                                text: [
                                    { text: ts( 'director' ) + ':  ', style: 'em' },
                                    { text: event.value.director?.shorten( 50 ), style: 'common' },
                                ],
                            },
                            {
                                text: [
                                    { text: ts( 'sponsor' ) + ':  ', style: 'em' },
                                    { text: event.value.sponsor?.shorten( 50 ), style: 'common' },
                                ],
                            },
                        ],
                    ],
                },
                layout: {
                    vLineWidth: () => ( 0 ),
                    hLineWidth: () => ( 0 ),
                },
            },
            {
                marginTop: 8,
                marginBottom: 8,
                table: {
                    headerRows: 0,
                    widths: [ '*', '*' ],
                    body: [
                        [
                            {
                                marginTop: 4,
                                marginBottom: 4,
                                marginLeft: 4,
                                text: [
                                    { text: ts( 'boatClass' ) + ':  ', style: 'em' },
                                    {
                                        text: eventClass.boatClass.shortcut + ' ' + eventClass.boatClass.name,
                                        style: 'em',
                                    },
                                ],
                            },
                            {
                                marginTop: 4,
                                marginBottom: 4,
                                marginRight: 4,
                                alignment: 'right',
                                text: [
                                    { text: ts( 'plannedCoefficient' ) + ':  ', style: 'em' },
                                    { text: eventClass.weight, style: 'em' },
                                ],
                            },
                        ],
                    ],
                },
                layout: {
                    vLineWidth: ( i, node ) => ( ( i === 0 || i === node.table.widths.length ) ? 1 : 0 ),
                    hLineWidth: ( i, node ) => ( ( i === 0 || i === node.table.body.length ) ? 1 : 0 ),
                    hLineColor: 'black',
                    vLineColor: 'black',
                },
            },
            {
                table: {
                    headerRows: 1,
                    widths: [ 'auto', 'auto', 'auto', '*', 'auto', 'auto', '*', ...( eventClass.boatClass.useBoatNames ? [ '*' ] : [] ), '*' ],
                    body: [
                        [
                            { text: ts( 'orderShortcut' ).toLowerCase(), style: 'em' },
                            { text: ts( 'sailNo' ).toLowerCase(), style: 'em' },
                            { text: ts( 'lastName' ).toLowerCase(), style: 'em' },
                            { text: ts( 'name' ).toLowerCase(), style: 'em' },
                            { text: ts( 'regId' ).toLowerCase(), style: 'em' },
                            { text: ts( 'categoryShortcut' ).toLowerCase(), style: 'em' },
                            { text: ts( 'bornShortcut' ).toLowerCase(), style: 'em' },
                            ...( eventClass.boatClass.useBoatNames ? [ {
                                text: ts( 'boatName' ).toLowerCase(),
                                style: 'em',
                            } ] : [] ),
                            { text: ts( 'club' ).toLowerCaseFirst(), style: 'em' },
                        ],
                        ...( attendedBoats.value.map( function ( boat ) {
                                                          let template = [
                                                              { text: sprintf( '%d.', ++counter ), alignment: 'right' },
                                                              boat.sailNo,
                                                              '',
                                                              '',
                                                              '',
                                                              '',
                                                              '',
                                                              ...( eventClass.boatClass.useBoatNames ? [ nvl( boat.name, '' ).shorten( 25 ) ] : [] ),
                                                              '',
                                                          ]

                                                          if ( boat.crew === undefined || boat.crew === null || boat.crew.length === 0 )
                                                              return [ template ]

                                                          return boat.crew.map( function ( crew ) {
                                                              let line = template
                                                              template = [ '', '', '', '', '', '', '', ...( eventClass.boatClass.useBoatNames ? [ '' ] : [] ), '' ]

                                                              line[ 2 ] = crew.lastName.shorten( 30 )
                                                              line[ 3 ] = crew.firstName.shorten( 30 )
                                                              line[ 4 ] = crew.regId
                                                              line[ 6 ] = crew.birthYear
                                                              line[ eventClass.boatClass.useBoatNames ? 8 : 7 ] = crew.visibleClub.shorten( 25 )

                                                              return line
                                                          } )
                                                      },
                        ).flat( 1 ) ),
                    ],
                },
                layout: {
                    vLineWidth: () => ( 0 ),
                    hLineWidth: ( i, node ) => ( ( i === node.table.headerRows || i === node.table.body.length ) ? 1 : 0 ),
                    vLineColor: 'black',
                },
                style: 'crewlist',
            },
        ],
        defaultStyle: {
            font: 'Roboto',
            fontSize: 10,
            opacity: .6,
        },
        styles: {
            h1: {
                fontSize: 16,
                bold: true,
                opacity: 1,
            },
            common: {},
            em: {
                bold: true,
                opacity: 1,
            },
            crewlist: {
                fontSize: 9,
            },
        },
    }

    pdfMake.createPdf( content ).download( 'ctl-' + event.value.ctlId + '-start-list-' + eventClass.boatClass.shortcut.toLowerCase() + '.pdf' )
}

function transformCrewFromServer ( crew, counter ) {
    const regId = crew.memberId.replace( '-', '' )
    const club = parseInt( regId.substring( 0, 4 ) )

    if ( club <= 8000 )
        return {
            memberId: regId,
            captain: crew.isCaptain === 1,
            order: counter,
        }

    if ( club === 8888 )
        return {
            nonMemberRegId: crew.memberId,
            nonMemberFirstName: crew.firstName,
            nonMemberLastName: crew.lastName,
            captain: crew.isCaptain === 1,
            nonMemberSex: crew.sex,
            nonMemberBirthDate: crew.birthDate,
            nonMemberEmail: crew.email,
            nonMemberPhone: crew.phone,
            order: counter,
        }

    if ( club === 9999 )
        return {
            nonMemberRegId: crew.memberId,
            nonMemberFirstName: crew.firstName,
            nonMemberLastName: crew.lastName,
            captain: crew.isCaptain === 1,
            nonMemberSex: crew.sex,
            nonMemberBirthDate: crew.birthDate,
            nonMemberBirthCode: crew.nationality === Country.CZ ? String( crew.birthCode ) : null,
            nonMemberCitizenship: crew.nationality,
            nonMemberAddress: crew.nationality === Country.CZ ? null : crew.address,
            nonMemberEmail: crew.email,
            nonMemberPhone: crew.phone,
            order: counter,
        }

    if ( club >= 9901 )
        return {
            nonMemberRegId: crew.memberId,
            nonMemberFirstName: crew.firstName,
            nonMemberLastName: crew.lastName,
            captain: crew.isCaptain === 1,
            nonMemberSex: crew.sex,
            nonMemberBirthDate: crew.birthDate,
            nonMemberEmail: crew.email,
            nonMemberPhone: crew.phone,
            order: counter,
        }

    return null
}

function logIn ( ev, attempts = 3, error = 0, firstTime = true ) {
    quasar.dialog( {
                       component: LogIn,
                       componentProps: {
                           errorMsg: error === 1
                                     ? ts( 'notAuthenticated' )
                                     : ( error === 2
                                         ? ts( 'unknownErrorDuringAuthentication' )
                                         : ( ! firstTime
                                             ? ts( 'authenticationExpired' )
                                             : '' )
                                     ),
                       },
                   } ).onOk( async ( payload ) => {
        const lastError = await authenticate( payload.username, payload.password )

        if ( useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken ) == '' ) {
            if ( attempts > 1 )
                logIn( attempts - 1, lastError )
            else
                quasar.dialog( {
                                   component: InfoBox,
                                   componentProps: {
                                       message: ts( 'tooManyAttempts' ),
                                   },
                               } )
        }
        else {
            await updateFromServer( ev, true )
        }
    } )
}

async function updateFromServer ( ev, afterLogin = false ) {
    const what = await checkAuth ()

    if ( what !== 'auth' ) {
        if ( afterLogin )
            return

        logIn( ev, 3, 0, what === 'unauth' )
    }

    if ( await checkAuth() !== 'auth' )
        return

    const currentClass = selClass.value === '-all' ? null : selClass.value

    apiRequest( useRepo( SettingsRepo ).getOption( Options.AuthToken, Defaults.AuthToken ) )
        .get( '/events/' + event.value.ctlId + '/boats' )
        .then( ( response ) => {
            if ( response.status === 200 ) {
                const current = currentClass === null
                                ? useRepo( EventBoat ).where( 'eventId', event.value.id ).withAllRecursive().get()
                                : useRepo( EventBoat ).where( 'eventId', event.value.id )
                                                      .where( 'boatClassId', String( currentClass ) ).withAllRecursive().get()

                const currentList = current.map( boat => String( boat.boatClassId ) + '|' + boat.country + '|' + boat.sailNumber )
                const newData = currentClass === null ? response.data : response.data.filter( ( boat ) => String( boat.classId ) === String( currentClass ) )
                const changeList = []
                const marked = []

                let iterator = 0

                newData.forEach( ( boat ) => {
                    const boatIdent = boat.classId + '|' + boat.country + '|' + boat.sailNumber

                    marked.push( boatIdent )

                    if ( currentList.indexOf( boatIdent ) >= 0 ) {
                        const crewList = []
                        let counter = 0

                        const origBoat = current.map( b => ( {
                            boatClassId: b.boatClassId,
                            country: b.country,
                            sailNumber: b.sailNumber,
                            id: b.id,
                            crewHash: b.crew.map( c => crewHash( c.dump ) ).join( '|' ),
                        } ) )
                                                .filter( b => b.boatClassId === parseInt( boat.classId )
                                                              && b.country === boat.country
                                                              && b.sailNumber === boat.sailNumber )[ 0 ]

                        const newCrewHash = boat.crew.map( ( crew ) => {
                            const newCrew = transformCrewFromServer( crew, ++counter )

                            if ( newCrew === null )
                                return

                            const hash = crewHash( crewDump( newCrew ) )

                            crewList.push( newCrew )

                            return hash
                        } ).join( '|' )

                        if ( origBoat.crewHash === newCrewHash )
                            return

                        changeList.push( {
                                             operation: 'update',
                                             id: ++iterator,
                                             original: {
                                                 boatId: origBoat.id,
                                                 boatClassId: parseInt( boat.classId ),
                                                 country: boat.country,
                                                 sailNumber: String( boat.sailNumber ),
                                                 crew: origBoat.crewHash.split( '|' ).map( hash => JSON.parse( hash ) ),
                                             },
                                             boat: {
                                                 id: origBoat.id,
                                                 eventId: event.value.id,
                                                 boatClassId: parseInt( boat.classId ),
                                                 country: boat.country,
                                                 sailNumber: String( boat.sailNumber ),
                                                 crew: crewList,
                                             },
                                         } )
                    }
                    else {
                        const crewList = []
                        let counter = 0

                        boat.crew.forEach( ( crew ) => {
                            const newCrew = transformCrewFromServer( crew, ++counter )

                            if ( newCrew !== null )
                                crewList.push( newCrew )
                        } )

                        changeList.push( {
                                             operation: 'add',
                                             id: ++iterator,
                                             boat: {
                                                 eventId: event.value.id,
                                                 boatClassId: parseInt( boat.classId ),
                                                 country: boat.country,
                                                 sailNumber: String( boat.sailNumber ),
                                                 crew: crewList,
                                             },
                                         } )
                    }
                } )

                currentList.filter( b => marked.indexOf( b ) === -1 ).forEach( ( boatIdent ) => {
                    const parts = boatIdent.split( '|' )

                    const origBoat = current.find( b => b.boatClassId === parseInt( parts[ 0 ] )
                                                        && b.country === parts[ 1 ]
                                                        && b.sailNumber === parts[ 2 ] )

                    changeList.push( {
                                         operation: 'remove',
                                         id: ++iterator,
                                         original: {
                                             boatId: origBoat.id,
                                             boatClassId: origBoat.boatClassId,
                                             country: origBoat.country,
                                             sailNumber: origBoat.sailNumber,
                                             crew: origBoat.crew,
                                         },
                                     } )
                } )

                const operOrder = {
                    add: 1,
                    update: 2,
                    remove: 3,
                }

                if ( changeList.length === 0 )
                    quasar.dialog( {
                                       component: InfoBox,
                                       componentProps: {
                                           message: ts( 'noDataForUpdate' ),
                                       },
                                   } )
                else
                    quasar.dialog( {
                                       component: UpdateRegistrations,
                                       componentProps: {
                                           changeList: changeList
                                               .sort( ( a, b ) =>
                                                          a.operation === b.operation
                                                          ? ( a.operation === 'remove'
                                                              ? ( a.original.country === b.original.country
                                                                  ? a.original.sailNumber - b.original.sailNumber
                                                                  : a.original.country.localeCompare( b.original.country )
                                                              )
                                                              : ( a.boat.country === b.boat.country
                                                                  ? a.boat.sailNumber - b.boat.sailNumber
                                                                  : a.boat.country.localeCompare( b.boat.country )
                                                              )
                                                          )
                                                          : operOrder[ a.operation ] - operOrder[ b.operation ],
                                               ),
                                       },
                                   } )
                          .onOk( ( payload ) => {
                              payload.changeList.forEach( ( change ) => {
                                  if ( change.operation === 'add' )
                                      useRepo( EventBoat ).save( change.boat )
                                  else if ( change.operation === 'remove' ) {
                                      useRepo( EventCrew ).where( 'eventBoatId', change.original.boatId ).delete()
                                      useRepo( EventResult ).where( 'eventBoatId', change.original.boatId ).delete()
                                      useRepo( EventBoat ).destroy( change.original.boatId )
                                  }
                                  else {
                                      useRepo( EventCrew ).where( 'eventBoatId', change.original.boatId ).delete()
                                      useRepo( EventBoat ).save( change.boat )
                                  }
                              } )
                          } )
            }
        } )
}
</script>

<style scoped lang="sass">
.boat-class-column
    width: 10em

.boat-country-column
    width: 6em

.boat-sailno-column
    width: 10em

tr.invalid > td
    color: $negative
    font-weight: bold

.minicrewlist
    font-size: 80%

.captain
    font-weight: bold

.current-boat, .current-boat div
    background-color: #ffffe7
</style>
