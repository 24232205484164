import { version } from '@/../package.json'
import apiRequest from "@/network/httpClient"
import { Defaults, Options } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { ts } from '@/plugins/i18n-formatted'
import CryptoJS from 'crypto-js'
import { useRepo } from "pinia-orm"
import { Notify, QSpinnerGears } from 'quasar'

// Authenticate to sailing.cz
export async function authenticate ( username, password ) {

    const encryptionPassphrase = 'p9wVp1vv1zMn1y86aDhiL0a3EYWDUrI6'

    const encryptedPassword = CryptoJS.AES.encrypt( password, encryptionPassphrase ).toString()

    const settingsRepo = useRepo( SettingsRepo )

    if ( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ).length > 0 )
        await apiLogOut( true )

    return apiRequest().post( '/auth/login', {
        username: username,
        password: encryptedPassword,
        software: 'scoring-system/' + version
    } ).then( response => {
        if ( response.status == 200 ) {
            settingsRepo.setOption( Options.AuthToken, response.data.token )
            settingsRepo.setOption( Options.AuthFullName, response.data.fullName )

            return 0
        } else {
            return 2
        }
    } ).catch( function ( error ) {
        return error.response.status === 401 ? 1 : 2
    } )
}

export async function apiLogOut () {
    const settingsRepo = useRepo( SettingsRepo )

    apiRequest( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ) )
        .post( '/auth/logout' )
        .then( () => {
        } )
        .catch( () => {
        } )
        .finally( function () {
            useRepo( SettingsRepo ).setOption( Options.AuthToken, Defaults.AuthToken )
            useRepo( SettingsRepo ).setOption( Options.AuthFullName, Defaults.AuthFullName )
        } )
}

export async function checkAuth () {
    const settingsRepo = useRepo( SettingsRepo )

    const authToken = settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken )

    if ( authToken == '' )
        return 'unauth'

    await apiRequest( authToken )
        .get( '/auth/check' )
        .catch( ( error ) => {
            if ( error.status === 401 ) {
                Notify.create( {
                                 type: 'secondary',
                                 spinner: QSpinnerGears,
                                 message: ts( 'notAuthNow' ),
                                 timeout: 2000
                             } )

                settingsRepo.setOption( Options.AuthToken, Defaults.AuthToken )
                settingsRepo.setOption( Options.AuthFullName, Defaults.AuthFullName )
            }
        } )

    if ( settingsRepo.getOption( Options.AuthToken, Defaults.AuthToken ) == '' )
        return 'expired'

    return 'auth'
}
