<template>
    <common-page
        :label="ts( 'settings' )" :use-second-button="false"
        @ok="save()">
        <q-select
            v-model="language" :label="ts( 'language' )"
            :options="[ { label: ts( 'czech' ), value: Language.Czech }, { label: ts( 'english' ), value: Language.English } ]"
            option-value="value" option-label="label" emit-value map-options input-debounce="300" />
        <q-input v-model="shownRaces" type="number" :label="ts( 'shownEvents' )" min="1" step="1" />
        <q-select
            v-model="eventListOrder" :label="ts( 'eventListOrder' )"
            :options="[ { label: ts( 'orderLastOpened' ), value: EventListOrder.LastOpened }, { label: ts( 'orderCreated' ), value: EventListOrder.Created }, { label: ts( 'orderEventTerm' ), value: EventListOrder.EventTerm } ]"
            option-value="value" option-label="label" emit-value map-options input-debounce="300" />
        <q-select
            v-model="raceResultStyle" :label="ts( 'raceResultStyle' )"
            :options="[ { label: ts( 'scrollableTable' ), value: RaceResultStyle.ScrollableTable }, { label: ts( 'dragAndDropTable' ), value: RaceResultStyle.DragAndDropTable } ]"
            option-value="value" option-label="label" emit-value map-options input-debounce="300" />
    </common-page>
</template>

<script setup lang="ts">
// Purpose: create new event manually

import CommonPage from "@/components/parts/commonPage.vue"
import { Defaults, EventListOrder, Language, Options, RaceResultStyle } from "@/orm/models/Setting"
import SettingsRepo from "@/orm/repositories/SettingsRepo"
import { ts } from "@/plugins/i18n-formatted"
import { useRepo } from "pinia-orm"
import { ref } from "vue"
import { useRouter } from "vue-router"

const router = useRouter()

const settingsRepo = useRepo( SettingsRepo )

const shownRaces = ref( parseInt( settingsRepo.getOption( Options.RaceShown, Defaults.RaceShown ) ) )
const eventListOrder = ref( parseInt( settingsRepo.getOption( Options.EventListOrder, Defaults.EventListOrder ) ) )
const language = ref( parseInt( settingsRepo.getOption( Options.Language, Defaults.Language ) ) )
const raceResultStyle = ref( parseInt( settingsRepo.getOption( Options.PreferredRaceResultStyle, Defaults.PreferredRaceResultStyle ) ) )

function save () {
    settingsRepo.setOption( Options.RaceShown, shownRaces.value )
    settingsRepo.setOption( Options.EventListOrder, eventListOrder.value )
    settingsRepo.setOption( Options.Language, language.value )
    settingsRepo.setOption( Options.PreferredRaceResultStyle, raceResultStyle.value )

    router.push( { name: 'MainPage' } )
}
</script>
